export const products = [
  {
    title: 'Сборник развивающих заданий 3-4 года',
    shortTitle: 'Книга 3-4 года',
    id: 'p1book',
    categories: ['Общее развитие'],
    topics: ['Дети 3-4 года'],
    price: 580,
    img: '/static/images/products/mainbook.webp',
    additionalImages: ['/static/images/products/mainbook.webp','/static/images/products/3_4/1.webp','/static/images/products/3_4/2.webp','/static/images/products/3_4/3.webp'],
    age: '3+',
    selected: false,
    description: 'Сборник заданий для деток в возрасте от 3 до 4 лет. /\n' +
      'В книге всего 80 заданий с яркими и красочными иллюстрациями. Все задания разбиты на темы. Всего выбрано 4 темы: математика, мышление, внимание и моторика. /\n' +
      'Задания в книге расположены таким образом, чтобы, можно было заниматься с ребенком по 15-20 минут в день, и при этом в полной мере охватить всю программу: потренировать память и мышление; уделить внимание таким важным областям знаний, как математика; подготовить руку к письму. /\n' +
      'Всего в книге десять занятий. Каждое занятие занимает четыре странички. Все задания сформированы и подходят под указынный возраст.',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: 'qw9YFjMNpeI',
    link: 'https://payform.ru/dr2OYs8/'
  },
  {
    title: 'Сборник развивающих заданий 2-3 года',
    shortTitle: 'Книга 2-3 года',
    id: 'p2book',
    categories: ['Общее развитие'],
    topics: ['Дети 2-3 года'],
    price: 580,
    img: '/static/images/products/book23.webp',
    additionalImages: [],
    age: '2+',
    selected: false,
    description: 'Сборник заданий для деток в возрасте от 2 до 3 лет. /\n' +
      'В книге всего 80 заданий с яркими и красочными иллюстрациями. Все задания разбиты на темы. Всего выбрано 4 темы: математика, мышление, окружающий мир и моторика. /\n' +
      'Задания в книге расположены таким образом, чтобы, можно было заниматься с ребенком по 15-20 минут в день, и при этом в полной мере охватить всю программу: потренировать мышление;\n' +
      'уделить внимание таким важным областям знаний, как\n' +
      'математика; подготовить руку к письму; поближе\n' +
      'познакомиться с окружающим миром и расширить кругозор. /\n' +
      'Всего в книге десять занятий. Каждое занятие занимает четыре странички. Все задания сформированы и подходят под указынный возраст.',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: 'X1Bd-FK4qpc',
    link: 'https://payform.ru/ei2OYsw/'
  },
  {
    title: 'Счёт до пяти. Учимся считать и писать',
    shortTitle: 'Счет 1-5',
    id: 'p3book',
    categories: ['Математика'],
    topics: ['Дети 3-4 года','Дети 4-5 лет'],
    price: 380,
    img: '/static/images/products/math.webp',
    additionalImages: [],
    age: '3+',
    selected: false,
    description: 'Тетрадь-пропись поможет научить ребёнка считать от одного до пяти и писать цифры от 1 до 5./\n' +
      'В тетради  38 страниц с учебным материалом, заданиями и прописями.  Это пособие - прекрасный старт для знакомства малыша с цифрами. /\n' +
      'Помните о нормах развития. Счет, грамота и чтение - это уровень предшкольного возраста. Не торопите своего ребенка. Если малыш не готов учить цифры, предложите ему игры по возрасту.',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: 'CbYunsw7tBo',
    link: 'https://payform.ru/k82OYvy/'
  },
  {
    title: 'Карточки “Фрукты и ягоды” 32 шт',
    shortTitle: 'Карточки фрукты',
    id: 'cards1Fruits',
    categories: ['Общее развитие','Окружающий мир','Английский язык'],
    topics: ['Малыши 0-1 года', 'Малыши 1-2 года', 'Дети 2-3 года', 'Дети 3-4 года', 'Дети 4-5 лет'],
    price: 490,
    img: '/static/images/products/fruits.webp',
    additionalImages: [],
    age: '0+',
    selected: false,
    description: 'Набор карточек для самых маленьких. В наборе 32 карточки с яркими картинками всех самых популярных фруктов и ягод. А для расширения кругозора в список добавлены еще и экзотические фрукты./\n' +
      'На карточках изображены фото настоящих фруктов и ягод, чтобы ребенок сразу привыкал к естественному виду плодов./\n' +
      'На обороте каждой карточки малыша ждут загадки для закрепления изученных слов. /\n' +
      'Также все слова имеют перевод на английский язык, чтобы ребёнок сразу запоминал слова на двух языках. /\n' +
      'К набору идет отдельный лист с информацией для родителей, по использованию карточек./\n' +
      'При желании карточки можно заламинировать, для удобства в использовании.',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: 'LpXWb5tF_28',
    link: 'https://payform.ru/kj2OYvJ/'
  },
  {
    title: 'Карточки “Овощи и зелень” 32 шт',
    shortTitle: 'Карточки Овощи',
    id: 'cards2Vegetables',
    categories: ['Общее развитие','Окружающий мир','Английский язык'],
    topics: ['Малыши 0-1 года','Малыши 1-2 года','Дети 2-3 года','Дети 3-4 года', 'Дети 4-5 лет'],
    price: 490,
    img: '/static/images/products/vegetables.webp',
    additionalImages: [],
    age: '0+',
    selected: false,
    description: 'Набор карточек для самых маленьких. В наборе 32 карточки с яркими картинками всех самых популярных овощей. А для расширения кругозора в список добавлена еще и зелень./\n' +
      'На карточках изображены фото настоящих овощей, чтобы ребенок сразу привыкал к естественному виду плодов./\n' +
      'На обороте каждой карточки малыша ждут загадки для закрепления изученных слов. /\n' +
      'Также все слова имеют перевод на английский язык, чтобы ребёнок сразу запоминал слова на двух языках. /\n' +
      'К набору идет отдельный лист с информацией для родителей, по использованию карточек./\n' +
      'При желании карточки можно заламинировать, для удобства в использовании.',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: 'I5rcpEO0LgM',
    link: 'https://payform.ru/m72OYwB/'
  },
  {
    title: 'Карточки ”Учим животных” 32 шт',
    shortTitle: 'Карточки животные',
    id: 'cards3Animals',
    categories: ['Общее развитие','Окружающий мир','Английский язык'],
    topics: ['Малыши 0-1 года','Малыши 1-2 года','Дети 2-3 года','Дети 3-4 года', 'Дети 4-5 лет'],
    price: 490,
    img: '/static/images/products/animals.webp',
    additionalImages: [],
    age: '0+',
    selected: false,
    description: 'Набор карточек для самых маленьких. В наборе 32 карточки с яркими картинками всех самых популярных животных./\n' +
      'На карточках изображены фото настоящих зверей, чтобы ребенок сразу привыкал к естественному виду животных./\n' +
      'На обороте каждой карточки малыша ждут загадки для закрепления изученных слов. /\n' +
      'Также все слова имеют перевод на английский язык, чтобы ребёнок сразу запоминал слова на двух языках. /\n' +
      'К набору идет отдельный лист с информацией для родителей, по использованию карточек./\n' +
      'При желании карточки можно заламинировать, для удобства в использовании.',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: 'Xa-u9Uoghek',
    link: 'https://payform.ru/me2OYwI/'
  },
  {
    title: 'Карточки “Бормоталки” 32 шт',
    shortTitle: 'Бормоталки',
    id: 'cards4Logoped',
    categories: ['Общее развитие','Логопедические карточки'],
    topics: ['Дети 2-3 года','Дети 3-4 года', 'Дети 4-5 лет'],
    price: 490,
    img: '/static/images/products/logoped1.webp',
    additionalImages: ['/static/images/products/logoped1.webp','/static/images/products/logoped1/1.webp'],
    age: '2+',
    selected: false,
    description: 'Набор логопедических карточек на возраст от 2-х лет для общего развития.\n /' +
      'Карточки “Бормоталки” - это набор общих звуков для\n' +
      'развития речи ребёнка. С их помощью вы развиваете речь\n' +
      'малыша весело и ненавязчиво, в игре.\n /' +
      'В наборе 32 двухсторонние карточки. При распечатке\n' +
      'получится так, что на обороте каждого звука будет стишок-\n' +
      'потешка для разучивания или дополнительного чтения.\n' +
      'Ребёнку очень важно на слух слышать как можно больше\n' +
      'разных слов.\n /' +
      'Каждая карточка содержит яркую, понятную и добрую иллюстрауию, которая будет соответсвовать смыслу\n' +
      'бормоталки.',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: '8VpLkQKv4qg',
    link: 'https://payform.ru/mm2OYwQ/'
  },
  {
    title: 'Карточки “Звукоподрожатели” 0+',
    shortTitle: 'Звукоподрожатели',
    id: 'cards4Logoped2',
    categories: ['Общее развитие','Логопедические карточки'],
    topics: ['Малыши 0-1 года','Малыши 1-2 года','Дети 2-3 года','Дети 3-4 года', 'Дети 4-5 лет'],
    price: 600,
    img: '/static/images/products/logoped2.webp',
    additionalImages: ['/static/images/products/logoped2.webp','/static/images/products/logoped2/2.webp'],
    age: '0+',
    selected: false,
    description: 'Разработано совместно с дефектологом-логопедом.\n /' +
      'Карточки “Звукоподрожатели” разработаны для запуска\n' +
      'речи у малышей. Комплект карточек является авторским и\n' +
      'был создан совместно с действующим дефектологом-логопедом.\n /' +
      'В наборе 32 двухсторонние карточки со слогами и 4 дополнительные карточки с инструкциями. \n /' +
      'На обороте\n' +
      'каждой карточки при распечатке, будет дополнительное\n' +
      'задание определенного цвета. Какой цвет и что обозначает, подробно расписано в карточках-инструкциях.\n /' +
      'Уникальность данного набора в том, что тут собраны все\n' +
      'самые простые и базовые звуки, подходящие именно под\n' +
      'запуск речи. А в дополнение к этому будет еще ряд\n' +
      'заданий на дыхание, моторику и слух, что позвлит\n' +
      'комплексно подойти к этому вопросу и ускорить запуск\n' +
      'речи у малыша.\n /' +
      'Данный набор является самым первым, с которого нужно начинать запуск речи у ребёнка.',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: 'thnBbRLeB5g',
    link: 'https://payform.ru/mw2OYx0/'
  },
  {
    title: 'Игра-карточки "Животные"',
    shortTitle: 'Животные на липучках',
    id: 'cards5StickyAnimals',
    categories: ['Общее развитие','Окружающий мир'],
    topics: ['Малыши 0-1 года','Малыши 1-2 года','Дети 2-3 года',],
    price: 280,
    img: '/static/images/products/stickyAnimals.webp',
    additionalImages: ['/static/images/products/stickyAnimals.webp','/static/images/products/stickyAnimals/1.webp','/static/images/products/stickyAnimals/2.webp'],
    age: '0+',
    selected: false,
    description: 'Интересная и познавательная игра в карточки.\n /' +
      'В наборе 2 двухсторонние карточки для изучения животных и окружающего мира. С одной стороны будет игра “Найди пару”, а с другой стороны дополнительные игры-развивашки.\n /' +
      'Задания в карточках направлены на развитие моторики, логики и мышления и расширения кругозора.\n /' +
      'Также карточки можно использовать как дополнение.Например: можно задавать ребенку много наводящих вопросов на развитие мышления, например: \n /' +
      '• Как разговаривает то или иное животное (показать самим или попросить показать ребенка);• Каких диких домашних животных малыш еще знает (пусть покажет пальчиком на картинке);• Где живут рыбки; • Покажи насекомого и т.д. ',
    payment: 'Добавьте желаемый товар или несколько товаров в корзину./\n' +
      'Нажмите кнопку оформить заказ, проверьте свой заказ и нажмите кнопку Оплатить. /\n' +
      'Обязательно проверьте точность указанного вами электронного адрес! /\n' +
      'На указанную вами почту, после прохождения оплаты придет письмо с сылкой или ссылками (если у вас несколько товаров). /\n' +
      'Перейдя по ссылке вам будет открыт доступ к файлам, можно скачивать, пользоваться в элекстронном формате или распечатывавть. Все файлы доступны в формате PDF. /\n Очень удобно.',
    video: '',
    link: 'https://payform.ru/np2OYxq/'
  },
]

export const sorting = [
  {
    label: 'По возрасту:',
    isActive: false,
    id: 's2',
    options: [
      {name:'Все', id: 's2.1', isSelected: false},
      {name:'Малыши 0-1 года', id:'s2.2', isSelected:false},
      {name:'Малыши 1-2 года', id:'s2.3', isSelected:false},
      {name:'Дети 2-3 года', id:'s2.4', isSelected:false},
      {name:'Дети 3-4 года', id:'s2.5', isSelected:false},
      {name:'Дети 4-5 лет', id:'s2.6', isSelected:false}
    ],
    defaultValue: 'Все'
  },
  {
    label: 'По темам:',
    isActive: false,
    id: 's3',
    options: [
      {name:'Все', id:'s3.1', isSelected: false},
      {name:'Математика', id:'s3.2', isSelected:false},
      {name:'Логопедические карточки', id:'s3.3', isSelected:false},
      {name:'Окружающий мир', id:'s3.4', isSelected:false},
      {name:'Английский язык', id:'s3.5', isSelected:false},
      {name:'Общее развитие', id:'s3.6', isSelected:false},
    ],
    defaultValue: 'Все'
  }
]